<template>
  <div>
    <div class="sselect">
      <div class="select-patent">
        <span class="sp-left">专利类型：</span>
        <span @click="chooseAllPatentType"
              :class="['sp-tag','display-flex-center',zllx.length===3 ? 'sp-tag-select' : '',]">全部类型
        <span class="sp-tag-tip" v-if="zllx.length===3"></span>
         <Icon v-if="zllx.length===3" class="sp-tag-circle" type="ios-checkmark"/>
        </span>
        <span @click="setPatentType(1)"
              :class="['sp-tag','display-flex-center',zllx.includes(1) ? 'sp-tag-select' : '',]">
          <span class="sp-tag-tip" v-if="zllx.includes(1)"></span>
         <Icon v-if="zllx.includes(1)" class="sp-tag-circle" type="ios-checkmark"/>
          发明专利</span>
        <span @click="setPatentType(2)"
              :class="['sp-tag','display-flex-center',zllx.includes(2) ? 'sp-tag-select' : '',]">实用新型
          <span class="sp-tag-tip" v-if="zllx.includes(2)"></span>
         <Icon v-if="zllx.includes(2)" class="sp-tag-circle" type="ios-checkmark"/>
        </span>
        <span @click="setPatentType(3)"
              :class="['sp-tag','display-flex-center',zllx.includes(3) ? 'sp-tag-select' : '',]">外观专利
          <span class="sp-tag-tip" v-if="zllx.includes(3)"></span>
         <Icon v-if="zllx.includes(3)" class="sp-tag-circle" type="ios-checkmark"/>
        </span>
      </div>
      <div class="select-patent">
        <span class="sp-left">法律状态：</span>
        <span @click="chooseAllLegalStatus"
              :class="['sp-tag','display-flex-center',flzt.length===4 ? 'sp-tag-select' : '',]">全部状态
           <span class="sp-tag-tip" v-if="flzt.length===4"></span>
         <Icon v-if="flzt.length===4" class="sp-tag-circle" type="ios-checkmark"/>
        </span>
        <span @click="setLegalStatus(1)"
              :class="['sp-tag','display-flex-center',flzt.includes(1) ? 'sp-tag-select' : '',]">在审
           <span class="sp-tag-tip" v-if="flzt.includes(1)"></span>
         <Icon v-if="flzt.includes(1)" class="sp-tag-circle" type="ios-checkmark"/>
        </span>
        <span @click="setLegalStatus(2)"
              :class="['sp-tag','display-flex-center',flzt.includes(2) ? 'sp-tag-select' : '',]">有效
           <span class="sp-tag-tip" v-if="flzt.includes(2)"></span>
         <Icon v-if="flzt.includes(2)" class="sp-tag-circle" type="ios-checkmark"/>
        </span>
        <span @click="setLegalStatus(3)"
              :class="['sp-tag','display-flex-center',flzt.includes(3) ? 'sp-tag-select' : '',]">无效
           <span class="sp-tag-tip" v-if="flzt.includes(3)"></span>
         <Icon v-if="flzt.includes(3)" class="sp-tag-circle" type="ios-checkmark"/>
        </span>
        <span @click="setLegalStatus(4)"
              :class="['sp-tag','display-flex-center',flzt.includes(4) ? 'sp-tag-select' : '',]">有效期届满
           <span class="sp-tag-tip" v-if="flzt.includes(4)"></span>
         <Icon v-if="flzt.includes(4)" class="sp-tag-circle" type="ios-checkmark"/>
        </span>
      </div>
      <div class="select-search">
        <Input v-model="input" style="width: 314px" search enter-button="搜索" placeholder="请输入关键词进行搜索"
               @on-search="search"/>
        <Select v-model="cgmc" style="width: 130px">
          <Option value="ans">申请号</Option>
          <Option value="pa">高校名称</Option>
          <Option value="pin">发明人</Option>
          <Option value="sic">专利分类号</Option>
          <Option value="agc">代理机构</Option>
          <Option value="agt">代理人</Option>
          <Option value="keywords">技术关键词</Option>
        </Select>
      </div>
    </div>
    <div class="rr-list">
      <div class="rl-body" v-for="(item,index) in data1" :key="index" @click="seeDetail(item)">
        <img class="rlb-img"
             src="https://cnipy-public.oss-cn-shanghai.aliyuncs.com/cnipy/web-static/znsearch/images/i2.png"/>
        <div class="rlb-center">
          <div class="rlbc-title ellipse-1">申请号：{{ item.an }} 丨 {{ item.ti }}</div>
          <div class="dp-flex"><span class="rlbc-tagA">{{ item.sectionName }}</span>
            <span class="rlbc-tagB">{{ item.lastLegalStatus }}</span>
          </div>
          <div class="rlbc-text ellipse-1">专利权利人：{{ item.pa }}</div>
          <div class="rlbc-text ellipse-1">专利发明人：{{ item.pin }}</div>
        </div>
        <div class="elb-right">
          <div class="elb-right-button display-flex-center">联系专利</div>
<!--          <div class="elb-right-bottom-button display-flex-center">关注</div>-->
        </div>
      </div>
      <div style="display: flex;flex-direction: row-reverse;padding: 40px 20px">
        <Page style="margin-top: 20px;float: right" :total="total"
              :page-size="params.pageSize" show-elevator @on-change="pageChange"/>
      </div>
    </div>
  </div>
</template>

<script>
import {getPatent} from "@/plugins/api/resourceApi";
import {warnToast, errorToast} from "@/plugins/tools/util";

export default {
  name: "ResourceRetrieval",
  mounted() {
    let keyword = this.$route.query.keyword
    this.params.keywords = keyword;
    this.cgmc = "keywords";
    this.input = keyword
    this.getPatentList();
  },
  data() {
    return {
      total: 10,
      params: {
        pageNum: 1,
        pageSize: 4,
        keywords: "",
        ans: "",
        pa: "",
        pin: "",
        agc: "",
        agt: "",
        sic: "",
        sectionName: "",
        lastLegalStatus: ""
      },
      cgmc: "",
      input: "",
      zllx: [1, 2, 3],//1.发明专利2.实用新型3.外观专利
      flzt: [1, 2, 3, 4],//1.在审2.有效3.无效4.有效期届满
      data1: []
    };
  },
  methods: {
    async getPatentList() {
      let flag = false;
      for (let item in this.params) {
        if (item !== 'pageNum' && item !== 'pageSize') {
          if (this.params[item] != "") {
            flag = true
          }
        }
      }
      if (flag == false) {
        warnToast("请至少选择一种进行查询")
      } else {
        let json = await getPatent(this.params);
        if (json.code == 0) {
          this.data1 = json.result.list;
          this.data1.forEach((item, index) => {
            if (item.sectionName == 'FMZL') {
              item.sectionName = '发明专利'
            }
            if (item.sectionName == 'FMSQ') {
              item.sectionName = '发明专利'
            }
            if (item.sectionName == 'SYXX') {
              item.sectionName = '实用新型'
            }
            if (item.sectionName == 'WGSJ') {
              item.sectionName = '外观设计'
            }
            if (item.sectionName == 'WGZL') {
              item.sectionName = '外观设计'
            }
          })
          this.total = json.result.total;

        } else {
          errorToast(json.message);
        }
      }

    },
    search() {
      this.params.pageNum = 1;
      this.params[this.cgmc] = this.input;
      this.sectionName = "";
      this.lastLegalStatus = "";
      for (let item in this.params) {
        if (this.params[item] !== "" && item !== this.cgmc) {
          this.params[item] = "";
        }
      }
      this.zllx.sort(this.sortArray)
      this.flzt.sort(this.sortArray)
      this.params.sectionName = this.zllx.join(',');
      this.params.lastLegalStatus = this.flzt.join(",");
      this.getPatentList()
    },
    setPatentType(type) {
      const index = this.zllx.indexOf(type)
      if (index !== -1) {
        this.zllx.splice(index, 1)
      } else {
        this.zllx.push(type)
      }
    },
    chooseAllPatentType() {
      if (this.zllx.length === 3) {
        this.zllx = []
      } else {
        this.zllx = [1, 2, 3]
      }
    },

    setLegalStatus(type) {
      const index = this.flzt.indexOf(type)
      if (index !== -1) {
        this.flzt.splice(index, 1)
      } else {
        this.flzt.push(type)
      }
    },
    chooseAllLegalStatus() {
      if (this.flzt.length === 4) {
        this.flzt = []
      } else {
        this.flzt = [1, 2, 3, 4]
      }
    },
    pageChange(index) {
      this.params.pageNum = index;
      this.getPatentList();
    },
    seeDetail(row, index) {
      let routeUrl = this.$router.resolve({
        path: "/home/ResourceDetail",//新页面地址
        query: {an: row.an}//携带的参数
      });
      window.open(routeUrl.href, "_blank");
    },
    sortArray(n1, n2) {
      return n1 - n2
    }
  }
};
</script>

<style scoped lang="scss">
.sselect {
  width: 1200px;
  border-radius: 6px;
  box-shadow: 1px 1px 10px rgba(229, 239, 249, 1);
  margin: 30px auto 20px auto;
}

.select-patent {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
  border-bottom: 1px solid #E4E4E4;;
}

.sp-left {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  margin-left: 20px;
}

.sp-tag {
  width: 100px;
  height: 30px;
  border: 1px solid #E4E4E4;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  margin-left: 20px;
  cursor: pointer;
  position: relative;
}

.sp-tag-tip {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 0;
  border-bottom: 20px solid rgba(15, 98, 223, 1);
  border-left: 20px solid transparent;
}

.sp-tag-circle {
  position: absolute;
  bottom: -9px;
  right: -6px;
  color: rgba(255, 255, 255, 1);
  font-size: 25px;
}

.sp-tag-select {
  border: 1px solid rgba(15, 98, 223, 1);
  color: rgba(15, 98, 223, 1);
}

.select-search {
  height: 70px;
  display: flex;
  flex-direction: row-reverse;
  padding-right: 20px;
  align-items: center;
}

.rr-list {
  width: 1200px;
  border-radius: 6px;
  box-shadow: 1px 1px 10px rgba(229, 239, 249, 1);
  margin: 20px auto 50px auto;
}

.rl-body {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 182px;
  cursor: pointer;
  border-bottom: 1px solid #E4E4E4;;
}
.rl-body:hover{
  box-shadow: 1px 1px 10px #0F62DF;
}

.rlb-img {
  width: 214px;
  height: 120px;
  margin-left: 20px;
  flex-shrink: 0;
}

.rlb-center {
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: hidden;
}

.rlbc-title {
  font-size: 20px;
  font-weight: 400;
  color: #333333;
}

.rlbc-title:hover{
  color: #0F62DF;
}

.rlbc-tagA {
  background-color: #FFDBCD;
  padding: 0 10px;
  font-size: 14px;
  font-weight: 400;
  color: #FE7930;
  border-radius: 4px;
  height: 26px;
  line-height: 26px;
}

.rlbc-tagB {
  background-color: rgba(202, 223, 254, 1);
  padding: 0 10px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(15, 98, 223, 1);
  border-radius: 4px;
  margin-left: 10px;
  height: 26px;
  line-height: 26px;
}

.rlbc-text {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
}

.elb-right {
  /*flex: 1;*/
  /*display: flex;*/
  /*flex-direction: row-reverse;*/
  margin-left: auto;
}

.elb-right-button {
  width: 120px;
  height: 34px;
  background: #DCEAFE;
  border: 1px solid #0F62DF;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: #0F62DF;
  margin-right: 37px;
}
.elb-right-bottom-button {
  width: 120px;
  height: 34px;
  background: #FFFFFF;
  border: 1px solid #0F62DF;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: #0F62DF;
  margin-right: 37px;
  margin-top: 10px;
}
  /deep/ .ivu-select-selection{
    height: 40px!important;
    line-height: 40px!important;
  }
  /deep/ .ivu-select-selected-value{
    line-height: 40px!important;
  }
</style>
